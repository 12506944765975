@font-face {
  font-family: NanumBarunGothic;
  src: url('../assets/fonts/NanumBarunGothic.ttf') format('truetype');
}
@font-face {
  font-family: NanumBarunGothicLight;
  src: url('../assets/fonts/NanumBarunGothicLight.ttf') format('truetype');
}
@font-face {
  font-family: Gmarket;
  src: url('../assets/fonts/GmarketSansTTFMedium.ttf') format('truetype');
}
@font-face {
  font-family: GmarketLight;
  src: url('../assets/fonts/GmarketSansTTFLight.ttf') format('truetype');
}
@font-face {
  font-family: GmarketBold;
  src: url('../assets/fonts/GmarketSansTTFBold.ttf') format('truetype');
}
@font-face {
  font-family: NanumSquareEB;
  src: url('../assets/fonts/NanumSquareEB.ttf') format('truetype');
}

@font-face {
  font-family: NanumSquareR;
  src: url('../assets/fonts/NanumSquareR.ttf') format('truetype');
}

@font-face {
  font-family: NanumSquareB;
  src: url('../assets/fonts/NanumSquareB.ttf') format('truetype');
}

@font-face {
  font-family: 'IBMPlexSansKR300';
  src: url('../assets/fonts/IBMPlexSansKR-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'IBMPlexSansKR400';
  src: url('../assets/fonts/IBMPlexSansKR-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'IBMPlexSansKR600';
  src: url('../assets/fonts/IBMPlexSansKR-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'IBMPlexSansKR700';
  src: url('../assets/fonts/IBMPlexSansKR-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 100;
  src: url('../assets/fonts/NotoSansKR-Thin.otf') format('truetype');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/NotoSansKR-Light.otf') format('truetype');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/NotoSansKR-Regular.otf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/NotoSansKR-Medium.otf') format('truetype');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/NotoSansKR-Bold.otf') format('truetype');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 900;
  src: url('../assets/fonts/NotoSansKR-Black.otf') format('truetype');
}

/* @media (prefers-color-scheme: dark) {
  body {
    color: #ffffff;
    background-color: #000000;
  }
  .iconImg {
    filter: brightness(3) contrast(1) saturate(150%);
  }
} */

/* 결제창 위치 스타일  */
.imp-dialog .imp-frame-pc.imp-frame-danal_tpay {
  /* margin-top: 150px;
  margin-left: -345px; */
  top: 50% !important;
  left: 50% !important;

  /* left: 50% !important; */
  width: 720px !important;
  height: 490px !important;
  transform: translate(-50%, -50%);
}

body.dark {
  opacity: 0.4;
}

html,
body,
div,
span,
applet,
object,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  padding: 0;
  margin: 0;
  font: inherit;
  font-family: 'Noto Sans KR' !important;
  font-weight: 300;
  vertical-align: baseline;
  border: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

button {
  all: unset;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

/* html,
body {
  font-family: "NanumBarunGothic";
  font-weight: 400;
  background-color: #fff;
  font-size: 16px;
  color: #000;
} */
a {
  color: inherit;
  text-decoration: none;
}

ul,
ol,
li {
  list-style-type: none;
}

#wrap {
  position: relative;
}

img {
  display: block;
  max-width: 100%;
  font-size: 0;
  line-height: 0;
  user-select: none;
  border: 0 none;

  /* user-select: none !important; */
  -webkit-user-drag: none !important;
  -khtml-user-drag: none !important;
  -moz-user-drag: none !important;
  -o-user-drag: none !important;
  user-drag: none !important;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

input {
  all: unset;
  padding: 1% 2%;
  font-family: inherit;
  background-color: #f4f4f4;
  border-radius: 0;
}

input[type='submit'] {
  cursor: pointer;
}

/* .gulim,
.gulim * {
  font-family: "gulim", "gothic";
} */
textarea {
  all: unset;
  padding: 2%;
  background-color: #f4f4f4;
}

/* Customize the label (the container) */
.container {
  position: relative;
  display: block;
  padding-left: 3.5rem;
  margin-bottom: 1.2rem;
  font-size: 2.2rem;
  cursor: pointer;
  user-select: none;
}

.winningcase {
  margin-bottom: 0;
}

/* Hide the browser's default checkbox */
.container input {
  width: 0;
  height: 0;
  cursor: pointer;

  /* position: absolute; */
  opacity: 0;
}

/* Create a custom checkbox */

.checkmark {
  position: absolute;
  top: 1rem;

  /* top: -0.5rem; */
  left: 0;
  width: 2rem;
  height: 2rem;
  background-color: #eeeeee;
}

.summaryMark {
  top: 50%;
  left: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #eeeeee;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #ca4d4d;
}

.winningcase input:checked ~ .checkmark {
  background-color: #ff6700;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark::after {
  position: absolute;
  display: none;
  content: '';
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark::after {
  display: block;
}

.winningcase .checkmark::after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark::after {
  top: 0.3rem;
  left: 0.6rem;
  box-sizing: content-box;
  width: 0.4rem;
  height: 0.8rem;
  border: solid white;
  border-width: 0 0.3rem 0.3rem 0;
  transform: rotate(45deg);
}

/* 라디오버튼 */

/* Create a custom radio button */
.checkmark_radio {
  position: absolute;
  top: 0;
  left: 0;
  width: 2rem;
  height: 2rem;
  background-color: #eeeeee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark_radio {
  background-color: #cccccc;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark_radio {
  background-color: #ca4d4d !important;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark_radio::after {
  position: absolute;
  display: none;
  content: '';
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark_radio::after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark_radio::after {
  top: 0.5rem;
  left: 0.5rem;
  width: 0.8rem;
  height: 0.8rem;
  background: white;
  border-radius: 50%;
}

@media screen and (min-width: 1200px) {
  html {
    font-size: 62.5%;
  }
}

@media screen and (max-width: 425px) {
  .container {
    padding-left: 12rem;
  }

  .checkmark {
    width: 8rem;
    height: 8rem;
  }

  .container .checkmark::after {
    top: 0.5rem;
    left: 1.5rem;
    width: 2rem;
    height: 3rem;
    border: solid white;
    border-width: 0 1rem 1rem 0;
    transform: rotate(45deg);
  }

  .checkmark_radio {
    width: 8rem;
    height: 8rem;
  }

  .container .checkmark_radio::after {
    top: 2rem;
    left: 2rem;
    width: 3rem;
    height: 3rem;
  }

  .container {
    padding-left: 12rem;
  }

  .checkmark {
    width: 8rem;
    height: 8rem;
  }

  .container .checkmark::after {
    top: 0.5rem;
    left: 1.5rem;
    width: 2rem;
    height: 3rem;
    border: solid white;
    border-width: 0 1rem 1rem 0;
    transform: rotate(45deg);
  }

  .checkmark_radio {
    width: 8rem;
    height: 8rem;
  }

  .container .checkmark_radio::after {
    top: 2rem;
    left: 2rem;
    width: 3rem;
    height: 3rem;
  }

  /* ---------------.winningcase */

  .winningcase {
    padding-left: 12rem;
  }

  .winningcase .checkmark {
    width: 8rem;
    height: 8rem;
  }

  .winningcase .checkmark::after {
    top: 0.5rem;
    left: 1.5rem;
    width: 2.4rem;
    height: 2.4rem;
    border: solid white;
    border-width: 0 1rem 1rem 0;
    transform: rotate(45deg);
  }

  .winningcase .checkmark_radio {
    width: 8rem;
    height: 8rem;
  }

  .winningcase .checkmark_radio::after {
    top: 2rem;
    left: 2rem;
    width: 2.4rem;
    height: 2.4rem;
  }

  .winningcase {
    padding-left: 12rem;
  }

  .winningcase .checkmark {
    width: 2.4rem;
    height: 2.4rem;
  }

  .winningcase .checkmark::after {
    top: 0.5rem;
    left: 1.5rem;
    width: 2.4rem;
    height: 2.4rem;
    border: solid white;
    border-width: 0 1rem 1rem 0;
    transform: rotate(45deg);
  }

  .winningcase .checkmark_radio {
    width: 2.4rem;
    height: 2.4rem;
  }

  .winningcase .checkmark_radio::after {
    top: 2rem;
    left: 2rem;
    width: 3rem;
    height: 3rem;
  }
}

@media screen and (min-width: 767px) and (max-width: 1199px) {
  html {
    font-size: 39%;
  }
}

@media screen and (min-width: 426px) and (max-width: 767px) {
  html {
    /* font-size: 25%; */
    font-size: 4px;
  }
}

@media screen and (max-width: 425px) {
  html {
    /* font-size: 13.8%; */
    font-size: 2.208px;
  }
}
