
.modalDispatcher-r {
  display: block;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10001;
  width: 100vw;
  height: 100vh;
    /* 주소 표시줄이 보이거나 숨겨질 때 뷰포트 높이 조정 */
  height: calc(var(--vh, 1vh) * 100);
  background-color: transparent;
  pointer-events: none;
  transition: height 0.2s ease-in-out;
}

.modalDispatcher-r .modalClearBtn-r {
  all: unset;
  position: relative;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: transparent;
}

.modalDispatcher-r.open-r .modalClearBtn-r {
  pointer-events: auto;
  cursor: pointer;
}

.modalDispatcher-r .modalWrapper-r {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}

.modalDispatcher-r .modalWrapper-r .closeModalCover-r {
  all: unset;
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: auto;
}

.modalDispatcher-r .modalWrapper-r .modalContentContainer-r {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: transparent;
}

.modalDispatcher-r .modalWrapper-r .modalContentContainer-r .modalContent-r {
  /* all: unset; */
  display: block;
  position: absolute;
  width: auto;
  height: auto;
}