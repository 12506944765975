@use '../../styles/constants/colors';
@use '../../styles/constants/fonts';
@import '../../globalStyle';
@import '../../reset/reset.css';

.legacyPager {
  .paginationWrapper {
    display: none !important;
  }

  .pagination {
    display: flex;
    justify-content: center;
    // margin-top: 15px;
  }

  ul {
    padding: 0;
    list-style: none;
  }

  ul.pagination li {
    // display: inline-block;
    display: flex;
    align-items: center;
    justify-content: center;
    // width: 30px;
    // height: 30px;
    // font-size: 1rem;
    // border: 1px solid #e2e2e2;
    // width: 4rem;
    min-width: 4rem;
    max-width: 12rem;
    height: 4rem;
    line-height: 1.5rem;
  }

  ul.pagination li:first-child {
    border-radius: 5px 0 0 5px;
  }

  ul.pagination li:not(:last-child) {
    margin-right: 1.2rem;
  }

  ul.pagination li:last-child {
    border-radius: 0 5px 5px 0;
  }

  ul.pagination li a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 2rem;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: normal;
    color: colors.$BLACK;
    text-decoration: none;
    letter-spacing: -0.19px;
    background-color: colors.$WHITE;
    border-radius: 6px;
  }

  ul.pagination li:nth-child(-n+2) a,
  ul.pagination li:nth-last-child(-n+2) a {
    font-size: 4rem;
    line-height: 0.5rem;
    color: colors.$WHITE;
    // background-color: colors.$BLACK;
  }

  // ul.pagination li:nth-child(-n+2) a {

  // }

  // ul.pagination li:nth-last-child(-n+2) a {

  // }

  ul.pagination li.active a {
    color: colors.$RED;
    // background-color: colors.$BLACK;
    background-color: rgba(255, 103, 0, 10%);
    border-radius: 6px;
  }

  ul.pagination li.active {
    // background-color: colors.$BLACK;
  }
  ul.pagination li.disabled {
    opacity: 0.3;
  }

  ul.pagination li a:hover,
  ul.pagination li a.active {
    color: colors.$RED;
  }

  .page-selection {
    width: 4.8rem;
    height: 3rem;
    color: #337ab7;
  }
}

// @media screen and (max-width: 425px) {
@media screen and (max-width: 767px) {
  body .legacyPager {
    // height: 9rem;
    ul.pagination li {
      // width: 17rem;
      min-width: 15rem;
      max-width: 30rem;
      height: 17rem;
      // font-size: 9rem;
      line-height: 7rem;
    }

    ul.pagination li a {
      padding: 3rem;
      // padding: calc((500px / 1920px) * 100%);
      font-size: 8rem;
      line-height: 9rem;
      // background-color: #f2f0ed;
    }
  }
}
@media all and (min-width: 425px) and (max-width: 767px) {
  body .legacyPager {
    // height: 9rem;
    ul.pagination li {
      width: 12rem;
      height: 12rem;
      // font-size: 9rem;
      line-height: 7rem;
    }

    ul.pagination li a {
      padding: 3rem;
      // padding: calc((500px / 1920px) * 100%);
      font-size: 5rem;
      line-height: 9rem;
      // background-color: #f2f0ed;
    }
  }
}
