@use '../../../styles/constants/colors';
@use '../../../styles/constants/fonts';
@import '../../../reset/reset.css';
@import '../../../globalStyle.scss';

.searchPager,
.lawyerPager {
  .paginationWrapper {
    display: none !important;
  }

  .pagination {
    display: flex;
    justify-content: center;
    // margin-top: 15px;
  }

  ul {
    padding: 0;
    list-style: none;
  }

  ul.pagination li {
    // display: inline-block;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.5rem;
    // width: 30px;
    // height: 30px;
    // font-size: 1rem;
    // border: 1px solid #e2e2e2;
  }

  ul.pagination li:first-child {
    border-radius: 5px 0 0 5px;
  }

  ul.pagination li:not(:last-child) {
    margin-right: 1.2rem;
  }

  ul.pagination li:last-child {
    border-radius: 0 5px 5px 0;
  }

  ul.pagination li a {
    height: 100%;
    padding: 1rem;
    font-size: 2.5rem;
    color: colors.$BLACK;
    text-decoration: none;
    background-color: colors.$WHITE;
  }

  ul.pagination li:nth-child(-n+2) a,
  ul.pagination li:nth-last-child(-n+2) a {
    font-size: 4rem;
    line-height: 0.5rem;
    color: colors.$WHITE;
    background-color: colors.$BLACK;
  }

  // ul.pagination li:nth-child(-n+2) a {

  // }

  // ul.pagination li:nth-last-child(-n+2) a {

  // }

  ul.pagination li.active a {
    color: colors.$RED;
    // background-color: colors.$BLACK;
  }

  ul.pagination li.active {
    background-color: colors.$BLACK;
  }

  ul.pagination li a:hover,
  ul.pagination li a.active {
    color: colors.$RED;
  }

  .page-selection {
    width: 4.8rem;
    height: 3rem;
    color: #337ab7;
  }
}

.lawyerPager {
  ul.pagination li a {
    // background-color: #f2f0ed;
  }
}

// @media screen and (max-width: 425px) {
@media screen and (max-width: 767px) {
  body .searchPager,
  .lawyerPager {
    // height: 9rem;

    ul.pagination {
      padding: calc((200px / 1920px) * 100%) 0;
    }

    ul.pagination li:not(:last-child) {
      // margin-right: calc((80px / 1920px) * 100%);
      margin-right: 3rem;
    }

    ul.pagination li:nth-child(-n+2) a,
    ul.pagination li:nth-last-child(-n+2) a {
      // padding: calc((700px / 1920px) * 100%) calc((800px / 1920px) * 100%);
      // font-size: 7rem;
      font-size: 14rem;
      // line-height: 10rem;
      line-height: 6rem;
    }

    ul.pagination li {
      // font-size: 9rem;
      line-height: 7rem;
    }

    ul.pagination li a {
      padding: 3rem;
      // padding: calc((500px / 1920px) * 100%);
      font-size: 8rem;
      line-height: 9rem;
      // background-color: #f2f0ed;
    }

    ul.pagination li.active a {
      padding: 3rem;
      color: #ca4d4d;
      // background-color: #f2f0ed;
    }
  }

  body .lawyerPager {
    ul.pagination li a {
      background-color: colors.$MOBILE_BACKGROUND;
    }
  }
}
@media all and (min-width: 425px) and (max-width: 767px) {
  body .searchPager {
    // height: 9rem;

    ul.pagination {
      padding-bottom: calc((150px / 1920px) * 100%);
    }

    ul.pagination li:not(:last-child) {
      margin-right: calc((80px / 1920px) * 100%);
    }

    ul.pagination li:nth-child(-n+2) a,
    ul.pagination li:nth-last-child(-n+2) a {
      padding: calc((700px / 1920px) * 100%) calc((800px / 1920px) * 100%);
      font-size: 7rem;
      line-height: 10rem;
    }

    ul.pagination li {
      // font-size: 9rem;
      line-height: 7rem;
    }

    ul.pagination li a {
      padding: 3rem;
      // padding: calc((500px / 1920px) * 100%);
      font-size: 7rem;
      line-height: 9rem;
      background-color: #f2f0ed;
    }

    ul.pagination li.active a {
      padding: 3rem;
      color: #ca4d4d;
      background-color: #f2f0ed;
    }
  }
}



@media all and (min-width: 700px) and (max-width: 1120px) {
  body .searchPager {

    ul.pagination {
      padding: 10rem;
       background: none; 
      color: black;
    }
    

    ul.pagination li:not(:last-child) {
    }

    ul.pagination li:nth-child(-n+2) a,
    ul.pagination li:nth-last-child(-n+2) a {
    }

    ul.pagination li {
    }

    ul.pagination li a {
    }

    ul.pagination li.active a {
    }
  }
}