@use '../../../styles/constants/colors';
@use '../../../styles/constants/fonts';

.bold {
  font-weight: 700;
}

.mainColor {
  color: colors.$RED;
}

.modal {
  overflow: hidden;
  font-family: fonts.$NOTO_SANS_KR, sans-serif;
  background-color: colors.$WHITE;
  border-radius: 12px;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 10%), 0 4px 6px -4px rgb(0 0 0 / 10%);

  .modalContainer {
    width: 100%;
    height: auto;
    min-height: 100%;
    transition: all 0.3s ease-in-out;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 6rem;
  overflow: hidden;
  background-color: colors.$RED;
}

.headerLeft {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: 100%;
  padding: 0 2rem;

  i,
  .tag {
    box-sizing: border-box;
    display: block;
    min-width: 5.6rem;
    height: 4rem;
    padding: 1rem 2rem;
    margin-right: 1.5rem;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2rem;
    text-align: center;
    border-radius: 4rem;

    &.icon {
      min-width: auto;
      background-color: transparent;
    }
  }

  h2,
  .title {
    font-family: fonts.$NOTO_SANS_KR, sans-serif;
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 3rem;
  }
}

.tag {
  box-sizing: border-box;
  display: block;
  min-width: 5.6rem;
  height: 4rem;
  padding: 1rem 2rem;
  margin-right: 1.5rem;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2rem;
  text-align: center;
  border-radius: 4rem;

  &.icon {
    min-width: auto;
    background-color: transparent;
  }
}

.title {
  font-family: fonts.$NOTO_SANS_KR, sans-serif;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 3rem;
}

.headerRight {
  .cancelBtn {
    display: block;
    width: auto;
    height: auto;
    padding: 0.4rem;
    margin: 0;
    cursor: pointer;
    border: none;
    outline: none;
  }
}

.main {
  box-sizing: border-box;
  width: 100%;
  height: auto;
  padding: 3rem;
  padding-bottom: 0;
}

.info,
.question {
  width: 100%;
  height: auto;
  font-size: 1.6rem;
  font-weight: 500;
  color: colors.$GRAY6;
  text-align: center;

  p {
    // width: 100%;
    // width: 50rem;
    height: auto;
    margin-top: 1rem;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    line-height: 3rem;
    color: inherit;

    b {
      font-weight: 700;
      color: colors.$GRAY4;
    }

    span {
      font-weight: 700;
      color: colors.$RED;
    }
  }

  h3 {
    box-sizing: border-box;
    display: block;
    width: 100%;
    height: 5rem;
    padding: 0 2rem;
    margin-top: 1rem;
    overflow: hidden;
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 5rem;
    color: colors.$GRAY4;
    text-emphasis: ellipsis;
    white-space: nowrap;
    background-color: colors.$GRAYF9;
  }

  h4 {
    padding-left: 10px;
    margin-top: 3rem;
    font-size: 1.6rem;
    font-weight: 500;
    color: colors.$GRAY4;
    text-align: left;
  }
}

.question {
  font-weight: 700;
  color: colors.$GRAY4;
}

.footer {
  box-sizing: content-box;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 5rem;
  padding: 4.5rem 0 5rem;
}

.closeBtn {
  box-sizing: border-box;
  display: block;
  width: 15rem;
  height: 5rem;
  overflow: hidden;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 4.8rem;
  color: colors.$GRAY4;
  text-align: center;
  cursor: pointer;
  background-color: colors.$WHITE;
  border-color: colors.$GRAYE4;
  border-style: solid;
  border-width: 1px;
  border-radius: 6px;
  transition: all 0.1s ease-in-out;

  &:hover {
    background-color: colors.$GRAYE4;
  }
}

.closeBtn.confirmBtn {
  color: colors.$WHITE;
  background-color: colors.$RED;
  border-color: colors.$RED;

  &:hover {
    background-color: colors.$ORANGE;
    border-color: colors.$ORANGE;
  }
}

.marginBox {
  width: 2rem;
  height: 100%;
}

.xIconBox {
  display: inline-block;
  width: auto;
  height: auto;
  padding: 0.5rem;
  background: transparent;
}

.xIcon {
  display: block;
  width: 4.2rem;
  height: 4.2rem;
  padding: 0;
  margin: 0;
}

.alert,
.confirm {
  width: 80vw;
  max-width: 500px;

  .confirmContents {
    width: 100%;
    height: auto;
    transition: all 0.3s ease-in-out;
  }
}

.circleIconBox {
  display: inline-block;
  width: auto;
  height: auto;
  padding: 0.2rem;
  border-radius: 50%;
}

.circleIcon {
  display: block;
  width: 2.6rem;
  height: 2.6rem;
  padding: 0;
  margin: 0;
}

.successIcon {
  color: rgb(0, 200, 83);
}

.warnIcon,
.errorIcon {
  color: rgb(220, 38, 38);
}

@keyframes activeModal {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  60% {
    transform: scale(1.15);
  }

  80% {
    transform: scale(0.95);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes closeModal {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    transform: scale(1.2);
  }

  100% {
    opacity: 0;
    transform: scale(0);
  }
}

@keyframes mobileActiveModal {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  60% {
    transform: scale(1.05);
  }

  80% {
    transform: scale(0.95);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes mobileCloseModal {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    transform: scale(1.05);
  }

  100% {
    opacity: 0;
    transform: scale(0);
  }
}

.warn,
.success,
.error {
  width: 90vw;
  max-width: 500px;

  .modalContainer {
    .main {
      padding-top: 4rem;
    }

    .footer {
      box-sizing: content-box;
      display: flex;
      justify-content: center;
      width: 100%;
      height: 5rem;
      padding: 4rem 0 3.5rem;
    }
  }
}

.warn,
.error {
  transform: scale(0);
}

.active {
  animation: activeModal 0.4s forwards;
}

.final {
  animation: closeModal 0.4s forwards;
}

$positive: #2bba50;
$loader-size: 32px;
$loader-thickness: 2px;
$loader-track-colour: #dcdee1;
$loader-colour: #666b76;
$check-thickness: 1.5px;

@keyframes e {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
}

@keyframes stroke {
  0% {
    stroke-dashoffset: 110;
    stroke-width: $check-thickness;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-width: $check-thickness;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0 0 0 30px $positive;
  }
}

.checkmarkContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 250px;

  .message {
    margin-top: 20px;
    font-size: 16px;
    font-weight: 500;
    color: colors.$GRAY4;
  }
}

.checkmark {
  display: inline-block;
  width: $loader-size;
  height: $loader-size;
  margin: 0;
  border-radius: 50%;
  box-shadow: inset 0 0 0 $positive;

  .circle {
    fill: none;
    stroke: none;
  }

  .check {
    fill: none;
    stroke: #ffffff;
    stroke-dasharray: 110;
    stroke-dashoffset: -110;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 0;
    transform-origin: 50% 50%;
  }
}

.xmark {
  display: inline-block;
  width: $loader-size;
  height: $loader-size;
  color: rgb(220, 38, 38);
  box-shadow: rgb(220, 38, 38);
}

.spinnerWrapper {
  position: relative;
  width: $loader-size;
  height: $loader-size;

  &.loaded {
    .spinner {
      border-color: $positive;
    }

    .checkmark {
      animation:
        fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 50ms forwards,
        scale 200ms cubic-bezier(0.4, 0, 0.2, 1) 300ms both;

      & .check {
        animation: stroke 300ms cubic-bezier(0.4, 0, 0.2, 1) 200ms forwards;
      }
    }
  }
}

.spinner {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: $loader-size - ($loader-thickness * 2);
  height: $loader-size - ($loader-thickness * 2);
  border: $loader-thickness solid $loader-track-colour;
  border-left: $loader-thickness solid $loader-colour;
  border-radius: 50%;
  transition: border 50ms cubic-bezier(0.4, 0, 0.2, 1);
  transform: translateZ(0);
  transform-origin: center;
  animation: e 1.3s infinite linear;
}

@media screen and (min-width: 426px) and (max-width: 933px) {
  .circleIconBox {
    padding: 2px;
    border-radius: 50%;
  }

  .circleIcon {
    width: 22px;
    height: 22px;
  }

  .xIconBox {
    padding: 3px;
  }

  .xIcon {
    width: 25px;
    height: 25px;
  }

  .header {
    height: 50px;
    padding: 1.5rem 2rem;
    padding: 0;
  }

  .headerLeft {
    padding: 12px 20px;

    i,
    .tag {
      min-width: 52px;
      height: 26px;
      margin-right: 8px;
      font-size: 12px;
      font-weight: 400;
      line-height: 26px;
      border-radius: 30px;
    }

    h2,
    .title {
      font-size: 15px;
      font-weight: 500;
      line-height: 26px;
    }
  }

  .headerRight {
    padding: 9px 12px;

    .cancelBtn {
      width: 32px;
      height: 32px;
      padding: 0;
    }
  }

  .title {
    font-size: 15px;
    font-weight: 500;
    line-height: 26px;
  }

  .tag {
    min-width: 52px;
    height: 26px;
    margin-right: 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 26px;
    border-radius: 30px;
  }

  .main {
    padding: 30px 20px 0;
  }

  .info {
    font-size: 14px;
    font-weight: 400;

    p {
      line-height: 26px;
    }

    h3 {
      height: 50px;
      padding: 0 10px;
      margin-top: 10px;
      font-size: 18px;
      line-height: 50px;
    }

    h4 {
      padding-left: 10px;
      margin-top: 10px;
      font-size: 16px;
      text-align: left;
    }
  }

  .footer {
    height: 50px;
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .marginBox {
    width: 20px;
  }

  .closeBtn {
    width: 130px;
    height: 45px;
    font-size: 15px;
    font-weight: 500;
    line-height: 43px;
  }

  .warn,
  .success,
  .error {
    .modalContainer {
      .main {
        padding-top: 30px;

        .info {
          font-size: 14px;
          font-weight: 400;
        }
      }

      .footer {
        height: 50px;
        padding-top: 30px;
        padding-bottom: 25px;
      }
    }
  }

  .checkmarkContainer {
    height: 180px;
    min-height: 180px;
    overflow: hidden;

    .message {
      font-size: 15px;
    }
  }
}

@media screen and (max-width: 425px) {
  .circleIconBox {
    padding: 2px;
    border-radius: 50%;
  }

  .circleIcon {
    width: 22px;
    height: 22px;
  }

  .xIconBox {
    padding: 3px;
  }

  .xIcon {
    width: 25px;
    height: 25px;
  }

  .header {
    height: 50px;
    padding: 1.5rem 2rem;
    padding: 0;
  }

  .headerLeft {
    width: 83%;
    padding: 0 20px;

    >h3,
    >h2 {
      max-width: 50%;
      overflow-x: scroll;
    }

    i,
    .tag {
      min-width: 52px;
      height: 26px;
      margin-right: 8px;
      overflow-x: hidden;
      font-size: 12px;
      font-weight: 400;
      line-height: 26px;
      white-space: nowrap;
      border-radius: 30px;
    }

    h2,
    .title {
      // width: 92px;
      // overflow-x: hidden;
      overflow-x: scroll;
      font-size: 15px;
      font-weight: 500;
      line-height: 26px;
      line-height: 37px;
      white-space: nowrap;
    }
  }

  .tag {
    min-width: 52px;
    height: 26px;
    margin-right: 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 26px;
    border-radius: 30px;
  }

  .title {
    font-size: 15px;
    font-weight: 500;
    line-height: 26px;
  }

  .headerRight {
    padding: 9px 12px;

    .cancelBtn {
      width: 32px;
      height: 32px;
      padding: 0;
    }
  }

  .main {
    padding: 30px 20px 0;
  }

  .info {
    font-size: 14px;
    font-weight: 400;

    p {
      line-height: 26px;
    }

    h3 {
      height: 50px;
      padding: 0 10px;
      margin-top: 10px;
      font-size: 18px;
      line-height: 50px;
    }

    h4 {
      padding-left: 10px;
      margin-top: 20px;
      font-size: 16px;
      text-align: left;
    }
  }

  .footer {
    height: 50px;
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .marginBox {
    width: 20px;
  }

  .closeBtn {
    width: 130px;
    height: 45px;
    font-size: 15px;
    font-weight: 500;
    line-height: 43px;
  }

  .confirm {
    width: 100vw;
    padding-bottom: 10px;
    border-radius: 0;
  }

  .warn,
  .success,
  .error {
    .modalContainer {
      .main {
        padding-top: 30px;

        .info {
          font-size: 14px;
          font-weight: 400;
        }
      }

      .footer {
        height: 50px;
        padding-top: 30px;
        padding-bottom: 25px;
      }
    }
  }

  .active {
    animation: mobileActiveModal 0.4s forwards;
  }

  .final {
    animation: mobileCloseModal 0.4s forwards;
  }

  .checkmarkContainer {
    min-height: 180px;

    .message {
      font-size: 15px;
    }
  }
}
