@use '../../styles/constants/colors';
@use '../../styles/constants/fonts';
@import '../../globalStyle';

.summary_detail_show {
  >li {
    h1 {
      margin-bottom: 2.1rem;
      font-family: fonts.$IBM300;
      font-size: 3rem;
      text-align: left;
      letter-spacing: -0.88px;
    }
  }

  > :last-child {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 5rem 0;

    button {
      all: unset;
      justify-content: flex-end;
      width: 12%;
      padding: 1.25rem 0;
      font-size: 1.8rem;
      color: colors.$WHITE;
      text-align: center;
      letter-spacing: -0.63px;
      cursor: pointer;
      background-color: #413931;
    }

    > :first-child {
      margin-right: 0.6rem;
      background-color: colors.$RED;
    }
  }
}

.row_table {
  font-size: 1.8rem;

  > :first-child {
    background-color: #fafafa;
    border-top: 0.3rem solid colors.$BLACK;
  }

  > :nth-child(4),
  :nth-child(8) {
    display: flex;
    align-items: center;
  }

  .summaryDownload {
    >span {
      > div {
        >button {
          all: unset !important;
          width: 12.5% !important;
          padding: 0.7rem 0 !important;
          font-size: 1.8rem !important;
          color: colors.$WHITE !important;
          text-align: center !important;
          letter-spacing: -0.63px !important;
          cursor: pointer !important;
          background-color: colors.$BLACK !important;
        }
      }
    }
  }

  li {
    height: auto;
    padding: 1.75rem 0.75rem;
    border-bottom: 1px solid #dbdbdb;

    > span {
      > input {
        width: 96%;
        height: inherit;
      }
    }

    textarea {
      width: 96%;
      height: 24.5rem;
      line-height: 1.3;
    }

    >.resetBtn {
      all: unset;
      display: none;
      // width: 12.5%;
      width: 7.5%;
      padding: 0.7rem 0;
      font-size: 1.8rem;
      color: colors.$WHITE;
      color: colors.$WHITE;
      text-align: center;
      letter-spacing: -0.63px;
      cursor: pointer;
      background-color: colors.$RED;
    }
  }

  .requestAlarm {
    display: inherit;

    >div {
      padding: 2rem 0 0 15%;
    }
  }

  .input_li {
    padding: 0.75rem;

    > .label_middle {
      vertical-align: middle;
    }

    > .span_short_width {
      > span {
        // width: fit-content;
        width: 7rem;
        padding: 0 1%;
      }

      .minPrice {
        width: 22%;
      }

      .gsWon {
        position: relative;
        top: -1px;
        width: 12%;
        padding-left: 0;

        >span {
          position: relative;
          top: -1px;
          width: 60%;
          padding-right: 1rem;
          line-height: 4.1rem;
          background-color: colors.$GRAY_BACKGROUND;
        }
      }

      >.warningText {
        width: 33%;
        color: red;
      }

      >.resetBtn {
        all: unset;
        display: none;
        // width: 12.5%;
        width: 7.5%;
        padding: 0.7rem 0;
        font-size: 1.8rem;
        color: colors.$WHITE;
        color: colors.$WHITE;
        text-align: center;
        letter-spacing: -0.63px;
        cursor: pointer;
        background-color: colors.$RED;
      }

      > input {
        width: 30.92%;
      }

      .gsNumberInput {
        position: relative;
        top: -2px;
        width: 26%;
        padding-right: 0;
        text-align: right;
      }

      .date {
        width: 16.85%;
      }

      .cd {
        height: 2rem;
      }

      .minPriceDesc {
        padding: 1rem 0;
        font-size: 1.5rem;
        color: colors.$GRAY8;
      }

      .priceInputWrapper {
        display: flex;
        align-items: center;

        >span {
          width: fit-content;
        }

        >:first-child {
          display: contents;

          >:nth-child(2) {
            width: fit-content;
            padding: 0 2rem 0 1rem;
          }
        }
      }

      .priceButtonWrapper {
        padding-top: 1rem;

        >button {
          width: 7%;
          margin-right: 0.6rem;
        }
      }

      .numberInput {
        width: 33.3%;
      }
    }

    .flexColumn {
      display: flex;
      flex-direction: column;
    }

    .flexRow {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    >.saPrice {
      >input {
        width: 25.92% !important;
      }
    }

    .numberInput {
      width: 33.3%;
      text-align: end;
    }

    > .account {
      display: inline-block;
      // width: 90%;

      > :first-child {
        width: 10%;
        margin-right: 1%;
      }

      > :nth-child(2) {
        width: 19.8%;
      }

      :last-child {
        width: 50%;
        padding: 0 1%;
        font-size: 1.5rem;
        color: colors.$GRAY6;
        letter-spacing: -0.52px;
      }
    }

    .filebox {
      display: flex;
      align-items: center;
      :nth-child(3) { width: 22%; }

      >:last-child > div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 4rem;
        margin-left: 1rem;
        color: colors.$WHITE;
        text-align: center;
        background-color: colors.$BLACK;
      }
    }

    .caseDate {
      >div > div > div > input { width: 25%; }
    }

    >.saSuggestionWrapper {
      display: flex;
      flex-direction: column;
      width: 85%;

      >.resetBtn {
        all: unset;
        display: none;
        // width: 12.5%;
        width: 7.5%;
        padding: 0.7rem 0;
        font-size: 1.8rem;
        color: colors.$WHITE;
        color: colors.$WHITE;
        text-align: center;
        letter-spacing: -0.63px;
        cursor: pointer;
        background-color: colors.$RED;
      }

      >span {
        display: flex;
        width: 100%;

        .minPrice {
          color: colors.$GRAY8;
        }

        >span {
          padding: 0 1rem;
          line-height: 2.8;
        }

        >* {
          height: auto;
        }

        >:first-child {
          width: 15%;
        }

        >:nth-child(2) {
          width: 25%;
          margin: 0.6rem 0;
        }

        >:nth-child(3) {
          width: 5%;
          text-align: end;
        }

        >:nth-child(4) {
          width: 28%;
        }

        >:last-child {
          width: 42%;
          color: colors.$REALRED;
        }
      }
    }
  }

  label {
    display: inline-block;
    width: 15%;
    letter-spacing: -0.63px;
    vertical-align: top;
  }

  .caseType {
    width: 45%;

    > div {
      justify-content: flex-start;
      height: 4.5rem;

      >select {
        width: 49%;
        background-color: colors.$GRAY_BACKGROUND !important;
        border: none;
        opacity: 0.8;
      }
    }
  }

  span {
    display: inline-block;
    width: 85%;

    > div > :first-child {
      margin-right: 0.6rem;
    }

    > div > button {
      all: unset;
      width: 12.5%;
      padding: 0.7rem 0;
      font-size: 1.8rem;
      color: colors.$WHITE;
      text-align: center;
      letter-spacing: -0.63px;
      cursor: pointer;
      background-color: #413931;
    }

    .resetBtn {
      display: none;
    }

    > div > button:last-child {
      color: colors.$WHITE;
      background-color: colors.$RED;
    }
  }

  .bank {
    width: 10%;
  }

  .orange_text {
    color: colors.$RED;
  }

  .bold {
    font-weight: bold;
  }

  .saCostWrapper > span > span {
    width: fit-content;
  }
}

.text_counter {
  padding: 1.7rem 0;
}

.select_type {
  width: 20%;
  height: inherit;
  padding: 1%;
  margin-right: 1%;
  background-color: colors.$GRAY_BACKGROUND;
  border: none;
}

.title_space {
  margin-top: 1rem;
}

.suggestion_content {
  line-height: 1.72;
  letter-spacing: -0.63px;
}

.mobileOff {
  display: block;
}
@media screen and (max-width: 425px) {
  .mobileOff {
    display: none !important;
  }

  .summary_detail_show {
    >li {
      padding: 3rem 0;

      h1 {
        font-size: 9.85rem;
        line-height: 12.5rem;
        letter-spacing: -0.345rem;
      }
    }
  }

  .row_table {
    font-size: 7rem;
    line-height: 18rem;
    letter-spacing: -0.261rem;

    label {
      width: 35%;
      word-break: keep-all;
    }

    .fileInput {
      line-height: 10rem;
    }

    .saCostWrapper > span {
      display: grid;
    }

    span {
      width: 100%;
      overflow: hidden;
      color: colors.$GRAY8;
      text-overflow: ellipsis;

      > div {
        .summaryView {
          line-height: 14rem;
        }

        .summaryDownload {
          line-height: 14rem;
        }

        > button {
          width: fit-content;
          padding: 0 7rem;
          font-size: 6.85rem;
          text-align: center;
        }
      }
    }

    .caseTitle span {
      white-space: nowrap;
    }

    li {
      display: flex !important;
      align-items: center;

      textarea {
        width: 96%;
        height: 118.6rem;
        line-height: 1.5;
      }

      >span {
        >input {
          line-height: 17rem;
        }

        > .buttonsWrapper {
          padding: 1rem 0;
          line-height: 12rem;
        }
      }

      .suggestion_content {
        white-space: break-spaces;
        line-break: anywhere;
      }
    }

    .rowTitle {
      white-space: nowrap;
    }

    .jamoonTitle {
      line-height: 12rem;
    }

    .jamoonSummary {
      padding: 5rem 1.75rem;
    }

    .summaryDownload {
      span {
        line-height: 15rem;

        > div {
          width: 40%;

          >button {
            width: 100% !important;
            font-size: 7rem !important;
            border-radius: 0 !important;
          }
        }
      }
    }

    .input_li {
      padding: 2.1rem 0 2.1rem 1.75rem;

      >.resetBtn {
        display: none;
        width: 20%;
        padding: 0 3rem;
        margin: 0 0.6rem 0.6rem 0;
        margin: 4rem 6rem;
        font-size: 6.85rem;
        text-align: center;
      }

      >label {
        line-height: 1;
        word-break: keep-all;
      }

      .span_short_width {
        span {
          width: 9%;
          overflow: unset;
          color: colors.$BLACK;
        }

        .warningText {
          width: 100%;
        }

        .won {
          width: 16%;
        }

        .minPrice {
          width: 60%;
        }

        input {
          width: 85%;
          text-align: end;
        }

        .date {
          width: 75%;
        }

        .cd {
          height: 9rem;
        }

        .gsWon {
          width: 40%;

          >span {
            width: 60%;
            line-height: 19rem;
          }
        }

        >.resetBtn {
          display: none;
          width: 20%;
          padding: 0 3rem;
          // margin: 0 0.6rem 0.6rem 0;
          // margin: 4rem 6rem;
          margin: 4rem 0;
          font-size: 6.85rem;
          text-align: center;
        }

        .priceInputWrapper {
          display: flex;
          flex-direction: column;
          align-items: baseline;

          >:first-child {
            display: flex;
            width: 100%;

            >:last-child {
              width: 17%;
            }
          }
        }

        .priceButtonWrapper {
          padding-top: 2rem;

          >button {
            width: 20%;
            padding: 0 3rem;
            margin: 0 0.6rem 0.6rem 0;
          }
        }

        .numberInput {
          width: 78%;
        }
      }

      .accountInfoLabel {
        width: 35% !important;
      }

      > .account {
        display: flex;
        flex-direction: column;

        input,
        span {
          width: 100% !important;
          margin: 1.05rem;
        }

        :nth-child(2) {
          // line-height: 8rem;
        }

        :last-child {
          padding: 0;
          overflow: initial;
          font-family: fonts.$IBM300;
          font-size: 5rem;
          line-height: 8rem;
          letter-spacing: -0.245rem;
          word-break: keep-all;
          white-space: pre-wrap;
        }
      }

      .label_middle {
        width: 35%;
      }

      .date > div > div > div > input {
        width: 100%;
      }

      .suggestionContent {
        line-height: 10rem;
      }

      .caseType {
        width: 100%;

        .etcInput {
          margin-top: 2rem;
        }

        > div {
          height: 18rem;

          >select {
            width: 50%;
          }
        }
      }

      .filebox {
        width: 100%;

        .fileBoxButton {
          width: 25%;
        }
        :nth-child(3) { width: 70%; }

        >:last-child > div {
          height: 20rem;
        }
      }

      >.saSuggestionWrapper {
        width: 100%;

        >.resetBtn {
          display: none;
          width: 20%;
          padding: 0 3rem;
          margin: 4rem 0;
          font-size: 6.85rem;
          text-align: center;
        }

        >span {
          flex-direction: column;
          width: 100%;

          >span {
            width: 100% !important;
            line-height: 2;
          }

          >input {
            width: 96% !important;
          }
        }
      }

      >.saPrice {
        >input {
          width: 87% !important;
        }
      }
    }
  }

  .buttons_wrap {
    line-height: 15rem !important;

    button {
      width: 30% !important;
      margin: 5rem 0 !important;
      font-size: 7rem !important;
    }

    :first-child {
      margin-right: 3% !important;
    }
  }

  .right {
    justify-content: flex-end !important;
  }

  .select_type {
    width: 49%;
    height: 17rem;
  }
}
