.loading {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: #fff;

  .loadingContainer {
    position: relative;
    width: 100%;
    height: 100%;
  }
}

@keyframes rotation {
  0% { 
    transform: scale(1); 
  }
  25% { 
    transform: scale(1.2); 
  }
  50% {
    transform: scale(1); 
  }
  75% { 
    transform: scale(1.7); 
  }
  100% { 
    transform: scale(1); 
  }
}

.loadingImgBox {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
}

.loadingImg {
  animation: rotation 2.5s infinite linear;
  width: 150px;
  height: 112.36px;
}