@use '../../styles/constants/colors';
@use '../../styles/constants/fonts';
@import '../../globalStyle';
@import '../../reset/reset.css';

.commonPager {
  padding-bottom: 10rem;

  .paginationWrapper {
    display: none !important;
  }

  .pagination {
    display: flex;
    justify-content: center;
    // margin-top: 15px;
  }

  ul {
    padding: 0;
    list-style: none;
  }

  ul.pagination li {
    width: 4rem;
    height: 4rem;
  }

  ul.pagination li:first-child {
    border-radius: 5px 0 0 5px;
  }

  ul.pagination li:not(:last-child) {
    // margin-right: 1.2rem;
  }

  ul.pagination li:last-child {
    border-radius: 0 5px 5px 0;
  }

  ul.pagination li a {
    display: flex;
    align-items: center;
    justify-content: center;
    // width: 4rem;
    // height: 100%;
    // height: 4rem;
    // padding: 1rem;
    // padding: 0.6rem 1.4rem 0.5rem;
    // margin: 0 0 0 0.5rem;
    // font-size: 2.5rem;
    width: 100%;
    height: 100%;
    font-size: 2rem;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: normal;
    color: colors.$BLACK;
    text-decoration: none;
    letter-spacing: -0.19px;
    background-color: colors.$WHITE;
    border-radius: 6px;
  }

  ul.pagination li:nth-child(-n+2) a,
  ul.pagination li:nth-last-child(-n+2) a {
    margin-right: 0.5rem;
    // margin-top: 1rem;
    // line-height: 3.5rem;
    // font-size: 4rem;
    // line-height: 0.5rem;
    // color: colors.$WHITE;
    // background-color: colors.$BLACK;
  }

  .prevBtn {
    margin-right: 0.5rem;
  }

  .nextBtn {
    margin-left: 0.5rem;
  }

  ul.pagination li:nth-child(4) a {
    // margin-left: 0;
  }

  ul.pagination li:first-child a {
    // margin-left: 0;
  }

  // ul.pagination li:nth-child(-n+2) a {

  // }

  // ul.pagination li:nth-last-child(-n+2) a {

  // }

  ul.pagination li.active a {
    // background-color: colors.$BLACK;
    font-weight: bold;
    color: colors.$RED;
  }

  ul.pagination li.active {
  }

  ul.pagination li.disabled {
    opacity: 0.3;
  }

  ul.pagination li a:hover,
  ul.pagination li a.active {
    color: colors.$RED;
  }

  ul.pagination li.active a {
    color: colors.$RED;
    // background-color: colors.$BLACK;
    background-color: rgba(255, 103, 0, 10%);
  }

  .page-selection {
    width: 4.8rem;
    height: 3rem;
    color: #337ab7;
  }
}

// @media screen and (max-width: 425px) {
@media all and (min-width: 320px) and (max-width: 767px) {
  .commonPager {
    // padding-bottom: 6rem;

    .paginationWrapper {
    }

    .pagination {
    }

    ul {
    }

    ul.pagination li {
      // width: 3.3rem;
      // width: 4rem;
      // height: 4rem;
      // padding: 9px 15.5px 10px;
      // line-height: 21px;
    }

    ul.pagination li:first-child {
    }

    ul.pagination li:not(:last-child) {
    }

    ul.pagination li:last-child {

    }

    ul.pagination li a {
      margin: 0;
      // width: 3.2rem;
      // width: 100%;
    }

    ul.pagination li:nth-child(-n+2) a,
    ul.pagination li:nth-last-child(-n+2) a {

    }

    // ul.pagination li:nth-child(-n+2) a {

    // }

    // ul.pagination li:nth-last-child(-n+2) a {

    // }

    ul.pagination li:nth-child(4) a {
    }

    ul.pagination li:first-child a {

    }

    ul.pagination li.active a {

    }

    ul.pagination li.active {
    }

    ul.pagination li.disabled {

    }

    ul.pagination li a:hover,
    ul.pagination li a.active {

    }

    ul.pagination li.active a {

    }

    .page-selection {

    }
  }
}
