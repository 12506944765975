$RED: #ff6700;
$ORANGE: #ff9e1a;
$TEALGREEN: #00a3a3;
$BROWN: #4c4642;
$GREEN: #70ad47;
$REALRED: red;

$BLACK: #000000;
$BLACKE: #2f2a22;
$GRAY3: #333333;
$GRAY4: #323232;
$GRAY6: #666666;
$GRAY8: #888888;
$GRAY7: #777777;
$GRAY7D: #7d7d7d;
$GRAY7A: #7a7a7a;
$GRAY75: #757575;
$GRAY9: #999999;
$GRAY9B: #9b9b9b;
$GRAYA: #aaaaaa;
$GRAYC: #cccccc;
$GRAYD: #dddddd;
$GRAYL: #9b9893;
$GRAYS: #8e8a83;
$GRAYB: #bbbbbb;
$GRAY47: #474747;
$GRAYAB: #ababab;
$GRAYA4: #a4a4a4;
$GRAYE3: #e3e3e3;
$GRAYE4: #e3e5e8;
$GRAYE7: #e7e7e7;
$GRAYF5: #f5f5f5;
$GRAYF9: #f9f9f9;
$GRAY8A: #8392a5;
$GRAYF0: #f0f2f5;
$WHITE: #ffffff;
$GRAYD2: #999999;
$GRAY4L: #bbbbbb;
$GRAYQL: #dadada;
$GRAY2D: #636366;

$KAKAO_BROWN: #635d2b;
$KAKAO_YELLOW: #fee500;
$NAVER_GREEN: #03c75a;

$TABLE_HEADER_GRAY: #fafafa;
$BORDER_GRAY: #dbdbdb;
$BOX_BG_GRAY: #fbfbfb;
$GRAY_BACKGROUND: #f4f4f4;

$INPUT_BACKGROUND: #f2f2f2;
$INPUT_BORDER: #d5d5d5;

$BG_OPACITY: rgba(0, 0, 0, 60%);
$MOBILE_BACKGROUND: #f2f0ed;
