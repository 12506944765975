$GMARKET: 'Gmarket';
$GMARKET_BOLD: 'GmarketBold';
$GMARKET_LIGHT: 'GmarketLight';
$NANUM: 'Noto Sans KR';
$LIGHT: 'Noto Sans KR';
$NANUMS_R: 'NanumSquareR';
$NANUMS_EB: 'NanumSquareEB';
$NANUMS_B: 'NanumSquareB';
// $IBM: 'IBMPlexSansKR400'; // IBMP를 전체 바꿈
$IBM: 'Noto Sans KR';
$IBM300: 'Noto Sans KR';
$IBM500: 'Noto Sans KR';
$IBM600: 'Noto Sans KR';
$NOTO_SANS_KR: 'Noto Sans KR';
