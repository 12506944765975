@use '../../styles/constants/colors';
@use '../../styles/constants/fonts';
@import '../../reset/reset.css';

.summarySliderBox {
  position: relative;
  // width: 56%;
  // width: 80rem;
  // width: 110rem;
  // max-width: 1400px;
  // width: 128rem;
  max-width: 1280px;
  height: 22rem;
  margin: 0 auto;
}

.summarySliderBox .slick-list {
  // max-width: 1261px;
  max-width: 1280px;
  // height: 28rem;
  margin: 0 -22px !important;
}

.summarySliderBox .slick-slide > div {
  padding: 0 22px !important;
}

.summarySliderBox .slick-slide {
  // width: 36.4rem !important;
  // width: 42.7rem !important;
  // width: 40rem !important;
  // width: 41.5rem !important;
  // width: 42.1rem !important;
  // padding: 0 2rem;
}

.soaecSliderBox .slick-slide {
  width: 42.7rem !important;
}

.summarySliderBox .slick-slide:nth-child(5n),
.summarySliderBox .slick-slide:nth-child(7n) {
  // margin: 0 4.4rem;
}

.summarySliderBox .slick-slide > div > div {

}

.summarySliderBox .prev {
  position: absolute;
  // top: 44%;
  top: 10.4em;
  // left: 0;
  left: -1.5%;
  z-index: 10;
  cursor: pointer;
  transform: translateY(-50%);
}

.summarySliderBox .next {
  position: absolute;
  // top: 41.5%;
  // top: 50%;
  // top: 44%;
  top: 10.4em;
  right: -1.6%;
  // right: 0;
  // right: -1.3%;
  z-index: 10;
  cursor: pointer;
  transform: translateY(-50%);
}

.summarySliderBox .slick-slide:nth-child(5n) > div {
  // margin: 0 3rem;
}

// .summarySliderBox .slick-prev,
// .summarySliderBox .slick-next {
//   position: absolute;
//   // top: (50% - calc((30px / 1280px) * 100%));
//   top: (50% - calc((130px / 1280px) * 100%));
//   z-index: 1;
//   display: block;
//   padding: 0;
//   font-size: 0;
//   line-height: 0;
//   cursor: pointer;
//   transform: translate(0, -(50% - calc((130px / 1280px) * 100%)));
// }

// .summarySliderBox .slick-prev {
//   left: 0;
// }

// .summarySliderBox .slick-next {
//   right: 5.2rem;
// }

// .summarySliderBox .slick-prev::before {
//   position: absolute;
//   width: 5rem; /* 사이즈 */
//   height: 5rem; /* 사이즈 */
//   content: '';
//   border-top: 1px solid #666666; /* 선 두께 */
//   border-right: 1px solid #666666; /* 선 두께 */
//   transform: rotate(225deg); /* 각도 */
// }

// .summarySliderBox .slick-next::before {
//   position: absolute;
//   width: 5rem; /* 사이즈 */
//   height: 5rem; /* 사이즈 */
//   content: '';
//   border-top: 1px solid #666666; /* 선 두께 */
//   border-right: 1px solid #666666; /* 선 두께 */
//   transform: rotate(45deg); /* 각도 */
// }

// @media all and (min-width: 1050px) and (max-width: 1240px) {
//   .summarySliderBox {
//     max-width: 800px !important;
//   }
// }

// @media screen and (min-width: 767px) and (max-width: 1200px) {
//   body {
//     .summarySliderBox {
//       max-width: 800px !important;
//     }
//   }
// }

@media screen and (min-width: 1199px) and (max-width: 1302px) {
  .summarySliderBox {
    // max-width: 1400px;
    // width: 128rem;
    // max-width: 120rem;
    max-width: 113rem;
  }
}
